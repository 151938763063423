import React from "react"

const ManualChopper = ({animation}) => {
  return (
    <div id="manual-chopper" className="w-100-sm animation-container">
      <div className={`animation ${animation}`} />
      <div className={`animation ${animation}`} />
      <div className={`animation ${animation}`} />
    </div>
  )
}

export default ManualChopper
