import React, { useState } from "react"
import { Waypoint } from "react-waypoint"
import ChevronRight from "../icons/chevronRight"
import InsulatedJug from "./InsulatedJug"
import ManualChopper from "./ManualChopper"
import MultiGrater from "./MultiGrater"

const Animation = ({ slug, product }) => {
  const [animation, setAnimation] = useState('')
  const toggleAnimation = (isVisible) => {
    if (isVisible) {
      setAnimation('animate')
    } else {
      setAnimation('reset')
    }
  }
  if (!product.animate) return <></>
  return (
    <div className="bg-grey">
      <div
        className="container-80 w-100 d-flex-lg justify-content-between h-100vh align-items-center"
        key={slug}
      >
        <div className="w-50-lg w-100-sm text-align-center-sm">
          <p className="font-size-66 w-100 font-size-25-sm font-bold m-0 mt-50 text-primary mb-20 product-title">{`${product.name}`}</p>
          <p className="text-secondary w-50-lg w-100-sm text-align-center-sm product-tag-line">
            {product.main_description}
          </p>
          <div className="my-150-lg my-50-sm">
            <a
              href="#productFeatures"
              className="text-info mr-10 letter-spacing"
            >
              Product Features
            </a>
            <ChevronRight />
          </div>
        </div>
        <Waypoint onEnter={() => toggleAnimation(true)} onLeave={() => toggleAnimation(false)} topOffset={100}>
          <div className="w-100-sm">
            {slug === "insulated-jug" && (
              <InsulatedJug animation={animation} />
            )}
            {slug === "manual-chopper" && (
              <ManualChopper animation={animation} />
            )}
            {slug === "multi-grater-and-slicer" && (
              <MultiGrater animation={animation} />
            )}
          </div>
        </Waypoint>
      </div>
      <div className="container-80 pb-50 ml-13-lg">
        <p className="text-align-center-sm">Colour Variants</p>
        <div className="d-flex justify-content-center-sm">
          {product.colors.map(color => {
            return (
              <div
                className="mr-10 color-box"
                style={{
                  backgroundColor: `${color}`,
                }}
                key={color}
              ></div>
            )
          })}
        </div>
        <p className="text-secondary font-size-16 text-align-center-sm">
          Customizable as per OEM orders
        </p>
      </div>
    </div>
  )
}

export default Animation
