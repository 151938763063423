import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import Animation from "components/animation"

import { PRODUCTS } from "../../../env"
import MoreProducts from "components/products/moreProducts"
import ProductFeature from "components/products/productFeature"
import ChevronRight from "components/icons/chevronRight"
import ChevronDown from "components/icons/chevronDown"

const ProductPage = ({ pageContext }) => {
  const product = PRODUCTS[pageContext.product]
  return (
    <div>
      {product && (
        <Layout path="/products/">
          <SEO
            title={`${product.name}`}
            description={`${product.main_description}`}
            keywords={[
              `Rishaba`,
              `Butterfly`,
              `Home Appliances`,
              `Industrial Design`,
              `Engineering`,
              `Mold Design`,
              `Manufacturing`,
            ]}
            ogUrl={`/products/${pageContext.product}`}
            image={product.set_image}
          />
          <div className="d-flex column align-items-center w-100">
            <h1 className="font-size-66 font-size-25-sm font-bold m-0 mt-50 text-primary product-title text-align-center">{`${product.name}`}</h1>
            <p className="text-secondary product-tag-line">
              Perfection in every aspect of our work
            </p>
            <div>
              <a href="/contact" className="text-info mr-10 letter-spacing">
                Contact
              </a>
              <ChevronRight />
            </div>
            <div className="w-60-lg w-100-sm position-relative animateFadeInUp">
              {product.set_image && (
                <img
                  className="w-100"
                  src={`${product.set_image}`}
                  alt={`${product.name}`}
                />
              )}
              {product.coming_soon && (
                <div className="bg-yellow border-radius-50 d-flex position-absolute align-items-center justify-content-center product-badge-standalone-lg product-badge-standalone-sm" >
                  <p className="text-align-center">Coming Soon</p>
                </div>
              )}
            </div>
            {!product.coming_soon && (
              <>
                <p className="text-secondary">Learn more about the Product</p>
                <ChevronDown fill={'black'} />
              </>
            )}
          </div>
          {product.main_image && (
            <div className="bg-grey">
              <div className="container-80 d-flex justify-content-between row-lg col-sm">
                <div className="w-50-lg w-100-sm text-align-center-sm">
                  <p className="font-size-66 w-100 font-size-25-sm font-bold m-0 mt-50 text-primary product-title mb-20">{`${product.name}`}</p>
                  <p className="text-secondary w-50-lg w-100-sm text-align-center-sm">
                    {product.main_description}
                  </p>
                  <div className="my-150-lg my-50-sm">
                    <a
                      href="#productFeatures"
                      className="text-info mr-10 letter-spacing"
                    >
                      Product Features
                    </a>
                    <ChevronRight />
                  </div>
                  <img
                    className="w-100 hide-lg"
                    src={`${product.main_image}`}
                    alt={`${product.name}`}
                  />
                  <p>Colour Variants</p>
                  <div className="d-flex justify-content-center-sm">
                    {product.colors.map(color => {
                      return (
                        <div
                          className="mr-10 color-box"
                          style={{ backgroundColor: `${color}` }}
                        ></div>
                      )
                    })}
                  </div>
                  <p className="text-secondary font-size-16 pb-50">
                    Customizable as per OEM orders
                  </p>
                </div>
                <div className="w-50-lg w-100-sm hide-sm">
                  <img
                    className="w-100 mt-150"
                    src={`${product.main_image}`}
                    alt={`${product.name}`}
                  />
                </div>
              </div>
            </div>
          )}
          <Animation slug={product.slug} product={product} />
          {!product.coming_soon && (
            <>
              <p className="font-size-h1 center text-primary">Key Features</p>
              <div name="productFeatures">
                {product.features &&
                  product.features.map((feature, index) => {
                    return (
                      <ProductFeature feature={feature} index={index} key={index} />
                    )
                  })}
              </div>
            </>
          )}
          <div className={`${product.coming_soon ? 'mt-50' : '' }`}>
            <MoreProducts />
          </div>
        </Layout>
      )}
    </div>
  )
}

export default ProductPage
