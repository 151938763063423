import React from 'react'

const MultiGrater = ({animation}) => {
  return (
    <div id="multi-grater" className="w-100-sm animation-container">
      <div className={`animation ${animation}`} />
      <div className={`animation ${animation}`} />
    </div>
  )
}

export default MultiGrater
