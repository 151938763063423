import React from 'react';

const ProductFeature = ({ feature, index }) => {
  return (
    <div className={`w-100 d-flex justify-content-between container my-50 col-sm ${index % 2 === 0 ? 'row-lg' : 'row-reverse-lg' }`} key={index}>
      <div className="w-50-lg w-100-sm d-flex column mt-100-lg">
        <h2 className="font-size-66 font-size-25-sm font-bold m-0 mt-50 text-primary mb-20 product-name center-sm" >{`${feature.name}`}</h2>
        <p className="text-secondary text-align-center-sm product-description">{feature.description}</p>
      </div>
      <img className="w-50-lg w-100-sm" src={`${feature.image}`} alt={feature} />
    </div>
  )
}

export default ProductFeature