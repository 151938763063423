import React from "react"

const InsulatedJug = ({animation}) => {
  return (
    <div id="insulated-jug" className="w-100-sm animation-container">
      <div className={`animation ${animation}`} />
      <div className={`animation ${animation}`} />
      <div className={`animation ${animation}`} />
      <div className={`animation ${animation}`} />
      <div className={`animation ${animation}`} />
    </div>
  )
}

export default InsulatedJug
